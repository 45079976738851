// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../jetbrains.woff2");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@font-face {\n    font-family: jetbrains;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\nhtml {\n    width: 100%;\n    height: 100%;\n    box-sizing: border-box;\n}\n\nbody {\n    height: 100%;\n    font-family: jetbrains;\n}\n\n.canvas {\n    height: 100%;\n    display: flex;\n    justify-content: center;\n}\n\n.content {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n}\n\n.profile {\n    display: flex;\n    flex-direction: row;\n}\n\n.avatar {\n    width: 33%;\n    align-content: center;\n}\n\n.avatar > img {\n    width:100%;\n    border-radius: 100%;\n    border: 5px solid white;\n}\n\n.name {\n    margin-left: 48px;\n    width: 67%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n}\n\n.links {\n    width: 100%;\n    margin-top: 64px;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-evenly;\n}\n\n.links > a {\n    font-size: 17px;\n}\n\n@media only screen and (max-device-width: 767px) and (orientation: portrait) {\n    .content {\n        width: 100%;\n    }\n\n    .avatar {\n        width: 400px;\n    }\n\n    .avatar > img {\n        border-width: 12px;\n    }\n\n    .profile {\n        flex-direction: column;\n        align-items: center;\n    }\n\n    .name {\n        width: 100%;\n        margin-top: 64px;\n        margin-left: 0;\n        align-items: center;\n    }\n\n    .name > h1 {\n        font-size: 70px;\n    }\n\n    .name > h3 {\n        font-size: 40px;\n    }\n\n    .links {\n        position: absolute;\n        bottom: 64px;\n    }\n\n    .links > a {\n        font-size: 36px;\n    }\n}\n\n@media only screen and (max-device-width: 767px) and (orientation: landscape) {\n    .avatar > img {\n        border-width: 7px;\n    }\n\n    .links > a {\n        font-size: 24px;\n    }\n}", ""]);
// Exports
module.exports = exports;
